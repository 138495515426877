import "./index.css";
import { Result } from "antd";

function App() {
  return (
    <>
      <p className="title">资源正在初始化</p>
      <Result
        status="warning"
        title=""
      ></Result>
      <div className="container">
        <div className="loader">
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--text"></div>
        </div>
      </div>
    </>
  );
}

export default App;
