import axios from "axios";
import { useEffect, useRef } from "react";

export function useInterval(callback, timeout = 1000) {
  const latestCallback = useRef(() => {});

  useEffect(() => {
    latestCallback.current = callback;
  });

  useEffect(() => {
    const timer = setInterval(() => latestCallback.current(), timeout);
    return () => clearInterval(timer);
  }, []);
}

export function detector() {
  const url = window.location.href;
  var parts = url.split('/');
  const endpoint = `${parts[0]}//${parts[2]}/${parts[3]}/auth/login`
  axios
    .head(endpoint)
    .then((rsp) => {
      console.log(JSON.stringify(rsp));
      if (rsp.headers["content-type"] != "application/json; charset=utf-8") {
        // 规则引擎的/auth/login接口，返回是"application/json; charset=utf-8"
        // nginx返回的是"text/html; charset=utf-8"
        setTimeout(() => {
          detector();
        }, 3000);
      } else {
        // 302是traefik代理请求到规则引擎容器实例后，容器实例返回的内容
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    })
    .catch((e) => {
      // 如果请求返回404，则认为是静态资源服务器返回的内容
      // 而不是规则引擎实例返回的内容，则判断为规则引擎实例还未启动
      // 继续探测
      setTimeout(() => {
        detector();
      }, 3000);
    });
  return null;
}
