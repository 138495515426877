import { detector } from "./utils/utils";
import "./App.css";
import Loading from "./pages/Loading1";
import { useEffect } from "react";
import 'antd/dist/antd.min.css';

function App() {
  useEffect(() => {
    detector();
  }, []);
  return (
    <div className="App">
      <header className="App-header">{Loading()}</header>
    </div>
  );
}

export default App;
